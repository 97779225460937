@import "https://fonts.googleapis.com/css?family=Montserrat:300,500,700,800";

h1,
h2,
h3,
h4,
h5,
span,
p,
ion-badge,
ion-input,
ion-item,
ion-label,
ion-select,
ion-text {
  font-family: Montserrat, sans-serif !important;
}
