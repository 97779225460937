@import "../helper";

ion-searchbar {
  --border-radius: 4px;
  --background: var(--rc-white);
  --box-shadow: 0 0 0 1px var(--rc-notsolight-grey);
}

@mixin search-bar-border {
  border-color: var(--ion-color-medium);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.search-bar-container {
  position: relative;
}

.search-result-list {
  background: white;
  position: absolute;
  top: 50px;
  z-index: 10;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
}
