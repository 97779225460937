.clickable {
  color: var(--ion-color-secondary);
  cursor: pointer;
}

.full-height {
  height: 100%;
}

.half-height {
  height: 50%;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.ion-hide-portrait {
  @media screen and (orientation: portrait) {
    display: none;
  }
}

.ion-hide-landscape {
  @media screen and (orientation: landscape) {
    display: none;
  }
}

.ion-no-padding-bottom {
  padding-bottom: 0;
}

.ion-no-padding-top {
  padding-top: 0;
}

.ion-no-padding-horizontal {
  padding-inline: 0;
}

.ion-margin-left {
  margin-left: 16px;
}

.ion-margin-right {
  margin-right: 16px;
}

.text-bold {
  font-weight: bold;
}

.text-bolder {
  font-weight: bolder;
}

.background-white {
  background: white;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin shadow {
  box-shadow: 0 5px 4px #00000029;
}

.card {
  background: white;
  padding: 16px;
  border: var(--rc-medium-grey) 1px solid;
}

.hidden {
  display: none;
}

.straight-line {
  border-bottom: 1px solid var(--ion-color-medium);
  margin-top: 30px;
}
