.ngx-datatable.material {
  .datatable-header {
    .datatable-header-cell {
      background: var(--rc-bright-orange);
      color: var(--rc-white);
      font-size: 16px;
    }
  }
}

.ngx-datatable.bootstrap {
  border-radius: 6px;
  border: var(--rc-light-grey) 1px solid;
  .datatable-header {
    .datatable-header-inner {
      .datatable-header-cell {
        border: 0;
      }
    }
  }
  .datatable-body {
    border-radius: 10px;
    overflow: hidden;
    .datatable-body-row {
      background-color: var(--rc-very-light-grey);
      border: 0 !important;
      cursor: pointer;
      .datatable-body-cell {
        padding: 0.75rem;
        text-align: left;
        display: flex;
        align-items: center;
      }
    }
    .datatable-body-row.datatable-row-even {
      background-color: var(--rc-white) !important;
    }
    .datatable-body-row:hover {
      background: var(--rc-hover-blue) !important;
    }
    .datatable-body-row.datatable-row-even:hover {
      background: var(--rc-hover-blue) !important;
    }
  }
  .datatable-footer {
    background: white !important;
    color: var(--rc-way-darker-grey) !important;
    .datatable-pager {
      .pager li a {
        color: var(--rc-light-grey-blue) !important;
      }
    }
  }
}

.ngx-datatable .datatable-header .datatable-header-cell.sortable .datatable-header-cell-wrapper {
  color: var(--rc-way-darker-grey);
  font-size: 14px;
  font-weight: 500;
}

.ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
  font-size: 20px;
  color: var(--rc-way-darker-grey);
  padding-left: 6px;
}

.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell,
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
  color: var(--rc-way-darker-grey);
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  color: var(--rc-very-light-grey) !important;
  background-color: var(--rc-light-grey-blue) !important;
}
