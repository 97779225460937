@import "theme/ion-components/ion-modal";
@import "theme/ion-components/ion-searchbar";
@import "theme/ngx-datatable";

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* ngx-datatable CSS */
@import "theme/font";

//// color outside app on iOS
html {
  background-color: var(--ion-color-primary);
}

//// Removing padding on footer to be replaced by safe-area-inset in app.component.scss
ion-footer.footer-toolbar-padding ion-toolbar:last-of-type {
  padding-bottom: 0 !important;
}

//Tooltips style
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  height: auto;
  background-color: var(--rc-black);
  color: var(--rc-white);
  text-align: center;
  border-radius: 6px;
  position: absolute;
  top: 10px;
  right: 90px;
  font-size: 13px;
  margin-left: -60px;
  opacity: 1;
  transition: opacity 0.3s;
  padding: 9px;
  z-index: 999;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  z-index: 999;
}

ion-modal {
  --width: 100% !important;
  --min-height: 95vh !important;
  --max-width: 850px !important;
}

.swiper-wrapper {
  display: flex;
  align-items: flex-end;
}

.alert-wrapper {
  max-width: unset !important;
  width: 40% !important;

  .alert-radio-label {
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    width: 70% !important;
  }
}

.sign-up-modal .modal-wrapper {
  height: 85%;
  top: 15%;
  position: absolute;
  display: block;
  border-radius: 20px;
}

.base-selection-modal .modal-wrapper {
  top: 25%;
  position: absolute;
  display: block;
  border-radius: 20px;

  --min-height: 20%;
  --max-height: 40%;
  --width: 30%;

  @media screen and (width <= 576px) {
    --min-height: 90%;
    --max-height: 90%;
    --width: 90%;
  }
}

.my-custom-slider .swiper-pagination {
  bottom: 8px;
}

.popover-wide .popover-content {
  left: 45px !important;
  width: 76%;
}

ion-searchbar:is(.with-border) {
  & > div {
    @include search-bar-border;
  }
}

.scan-video-feed {
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

// Hide all elements
body.barcode-scanner-active {
  visibility: hidden;
  --background: transparent;
  --ion-background-color: transparent;
}

// Show only the barcode scanner modal
.barcode-scanner-modal {
  visibility: visible;
}

@media (prefers-color-scheme: dark) {
  .barcode-scanner-modal {
    --background: transparent;
    --ion-background-color: transparent;
  }
}
