// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --content-padding: 16px;

  @media screen and (min-width: 768px) {
    --content-padding: 32px;
  }

  --rc-bright-orange: #f4672a;
  --rc-bright-orange-rgb: 244, 103, 42;
  --rc-dark-blue: #012b35;
  --rc-dark-blue-rgb: 1, 43, 53;

  --rc-light-grey-blue: #607d8b;
  --rc-grey-blue: #2c495a;
  --rc-accent: #91dbe4;
  --rc-accent-rgb: 145, 219, 228;
  --rc-dark: #1b2834;
  --rc-dark-rgb: 27, 40, 52;
  --rc-dark-grey: #bababa;
  --rc-darker-grey: #808080;
  --rc-way-darker-grey: #545454;

  --rc-dark-grey-rgb: 186, 186, 186;
  --rc-medium-grey: #faf6f3;
  --rc-medium-grey-rgb: 250, 246, 243;

  --rc-very-light-grey: #f8f8f8;
  --rc-light-grey: #ededed;
  --rc-notsolight-grey: #e3e3e3;

  --rc-white: #ffffff;
  --rc-black: #000000;

  --rc-hover-blue: #1b8daa1d;

  --ion-color-primary: var(--rc-dark-blue);
  --ion-color-primary-rgb: var(--rc-dark-blue-rgb);
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #01262f;
  --ion-color-primary-tint: #1a4049;
  --ion-color-secondary: var(--rc-bright-orange);
  --ion-color-secondary-rgb: var(--rc-bright-orange-rgb);
  --ion-color-secondary-contrast: var(--rc-medium-grey);
  --ion-color-secondary-contrast-rgb: var(--rc-medium-grey-rgb);
  --ion-color-secondary-shade: #d75b25;
  --ion-color-secondary-tint: #f5763f;
  --ion-color-tertiary: var(--rc-accent);
  --ion-color-tertiary-rgb: var(--rc-accent-rgb);
  --ion-color-tertiary-contrast: var(--rc-dark);
  --ion-color-tertiary-contrast-rgb: var(rc-dark-rgb);
  --ion-color-tertiary-shade: #80c1c9;
  --ion-color-tertiary-tint: #9cdfe7;
  --ion-color-success: #21b404;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-dark: var(--rc-dark);
  --ion-color-dark-rgb: var(--rc-dark-rgb);
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #18232e;
  --ion-color-dark-tint: #323e48;
  --ion-color-medium: var(--rc-dark-grey);
  --ion-color-medium-rgb: var(--rc-dark-grey-rgb);
  --ion-color-medium-contrast: #000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-light: var(--rc-medium-grey);
  --ion-color-light-rgb: var(--rc-medium-grey-rgb);
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dcd8d6;
  --ion-color-light-tint: #fbf7f4;

  // Colors dictionary
  --ion-color-black: #000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #fff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000;
  --ion-color-black-tint: #1a1a1a;
  --ion-color-white: #fff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #fff;
  --ion-color-red: #f00;
  --ion-color-red-rgb: 255, 0, 0;
  --ion-color-red-contrast: #fff;
  --ion-color-red-contrast-rgb: 255, 255, 255;
  --ion-color-red-shade: #e00000;
  --ion-color-red-tint: #ff1a1a;
  --ion-color-lime: #0f0;
  --ion-color-lime-rgb: 0, 255, 0;
  --ion-color-lime-contrast: #000;
  --ion-color-lime-contrast-rgb: 0, 0, 0;
  --ion-color-lime-shade: #00e000;
  --ion-color-lime-tint: #1aff1a;
  --ion-color-blue: #00f;
  --ion-color-blue-rgb: 0, 0, 255;
  --ion-color-blue-contrast: #fff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #0000e0;
  --ion-color-blue-tint: #1a1aff;
  --ion-color-aqua: #0ff;
  --ion-color-aqua-rgb: 0, 255, 255;
  --ion-color-aqua-contrast: #000;
  --ion-color-aqua-contrast-rgb: 0, 0, 0;
  --ion-color-aqua-shade: #00e0e0;
  --ion-color-aqua-tint: #1affff;
  --ion-color-yellow: #ff0;
  --ion-color-yellow-rgb: 255, 255, 0;
  --ion-color-yellow-contrast: #000;
  --ion-color-yellow-contrast-rgb: 0, 0, 0;
  --ion-color-yellow-shade: #e0e000;
  --ion-color-yellow-tint: #ffff1a;
  --ion-color-fuchsia: #f0f;
  --ion-color-fuchsia-rgb: 255, 0, 255;
  --ion-color-fuchsia-contrast: #fff;
  --ion-color-fuchsia-contrast-rgb: 255, 255, 255;
  --ion-color-fuchsia-shade: #e000e0;
  --ion-color-fuchsia-tint: #ff1aff;
  --ion-color-silver: #c0c0c0;
  --ion-color-silver-rgb: 192, 192, 192;
  --ion-color-silver-contrast: #000;
  --ion-color-silver-contrast-rgb: 0, 0, 0;
  --ion-color-silver-shade: #a9a9a9;
  --ion-color-silver-tint: #c6c6c6;
  --ion-color-gray: #808080;
  --ion-color-gray-rgb: 128, 128, 128;
  --ion-color-gray-contrast: #000;
  --ion-color-gray-contrast-rgb: 0, 0, 0;
  --ion-color-gray-shade: #717171;
  --ion-color-gray-tint: #8d8d8d;
  --ion-color-maroon: #800000;
  --ion-color-maroon-rgb: 128, 0, 0;
  --ion-color-maroon-contrast: #fff;
  --ion-color-maroon-contrast-rgb: 255, 255, 255;
  --ion-color-maroon-shade: #710000;
  --ion-color-maroon-tint: #8d1a1a;
  --ion-color-olive: #808000;
  --ion-color-olive-rgb: 128, 128, 0;
  --ion-color-olive-contrast: #fff;
  --ion-color-olive-contrast-rgb: 255, 255, 255;
  --ion-color-olive-shade: #717100;
  --ion-color-olive-tint: #8d8d1a;
  --ion-color-green: #008000;
  --ion-color-green-rgb: 0, 128, 0;
  --ion-color-green-contrast: #fff;
  --ion-color-green-contrast-rgb: 255, 255, 255;
  --ion-color-green-shade: #007100;
  --ion-color-green-tint: #1a8d1a;
  --ion-color-purple: #800080;
  --ion-color-purple-rgb: 128, 0, 128;
  --ion-color-purple-contrast: #fff;
  --ion-color-purple-contrast-rgb: 255, 255, 255;
  --ion-color-purple-shade: #710071;
  --ion-color-purple-tint: #8d1a8d;
  --ion-color-teal: #008080;
  --ion-color-teal-rgb: 0, 128, 128;
  --ion-color-teal-contrast: #fff;
  --ion-color-teal-contrast-rgb: 255, 255, 255;
  --ion-color-teal-shade: #007171;
  --ion-color-teal-tint: #1a8d8d;
  --ion-color-navy: #000080;
  --ion-color-navy-rgb: 0, 0, 128;
  --ion-color-navy-contrast: #fff;
  --ion-color-navy-contrast-rgb: 255, 255, 255;
  --ion-color-navy-shade: #000071;
  --ion-color-navy-tint: #1a1a8d;
  --ion-color-turquoise: #40e0d0;
  --ion-color-turquoise-rgb: 64, 224, 208;
  --ion-color-turquoise-contrast: #000;
  --ion-color-turquoise-contrast-rgb: 0, 0, 0;
  --ion-color-turquoise-shade: #38c5b7;
  --ion-color-turquoise-tint: #53e3d5;
  --ion-color-salmon: #fa8072;
  --ion-color-salmon-rgb: 250, 128, 114;
  --ion-color-salmon-contrast: #000;
  --ion-color-salmon-contrast-rgb: 0, 0, 0;
  --ion-color-salmon-shade: #dc7164;
  --ion-color-salmon-tint: #fb8d80;
  --ion-color-orange: #ffa500;
  --ion-color-orange-rgb: 255, 165, 0;
  --ion-color-orange-contrast: #000;
  --ion-color-orange-contrast-rgb: 0, 0, 0;
  --ion-color-orange-shade: #e09100;
  --ion-color-orange-tint: #ffae1a;
  --ion-color-beige: #f5f5dc;
  --ion-color-beige-rgb: 245, 245, 220;
  --ion-color-beige-contrast: #000;
  --ion-color-beige-contrast-rgb: 0, 0, 0;
  --ion-color-beige-shade: #d8d8c2;
  --ion-color-beige-tint: #f6f6e0;
  --ion-color-lavender: #726ab9;
  --ion-color-lavender-rgb: 230, 230, 250;
  --ion-color-lavender-contrast: var(--ion-color-white);
  --ion-color-lavender-contrast-rgb: 0, 0, 0;
  --ion-color-lavender-shade: #cacadc;
  --ion-color-lavender-tint: #e9e9fb;
  --ion-color-dodger: #1e90ff;
  --ion-color-dodger-rgb: 30, 144, 255;
  --ion-color-dodger-contrast: #fff;
  --ion-color-dodger-contrast-rgb: 255, 255, 255;
  --ion-color-dodger-shade: #1a7fe0;
  --ion-color-dodger-tint: #359bff;
  --ion-color-orchid: #9932cc;
  --ion-color-orchid-rgb: 153, 50, 204;
  --ion-color-orchid-contrast: #fff;
  --ion-color-orchid-contrast-rgb: 255, 255, 255;
  --ion-color-orchid-shade: #872cb4;
  --ion-color-orchid-tint: #a347d1;
  --ion-color-wheat: #f5deb3;
  --ion-color-wheat-rgb: 245, 222, 179;
  --ion-color-wheat-contrast: #000;
  --ion-color-wheat-contrast-rgb: 0, 0, 0;
  --ion-color-wheat-shade: #d8c39e;
  --ion-color-wheat-tint: #f6e1bb;
  --ion-color-pink: #ffb6c1;
  --ion-color-pink-rgb: 255, 182, 193;
  --ion-color-pink-contrast: #000;
  --ion-color-pink-contrast-rgb: 0, 0, 0;
  --ion-color-pink-shade: #e0a0aa;
  --ion-color-pink-tint: #ffbdc7;
  --ion-color-violet: #ee82ee;
  --ion-color-violet-rgb: 238, 130, 238;
  --ion-color-violet-contrast: #000;
  --ion-color-violet-contrast-rgb: 0, 0, 0;
  --ion-color-violet-shade: #d172d1;
  --ion-color-violet-tint: #f08ff0;
  --ion-color-bisque: #ffe4c4;
  --ion-color-bisque-rgb: 255, 228, 196;
  --ion-color-bisque-contrast: #000;
  --ion-color-bisque-contrast-rgb: 0, 0, 0;
  --ion-color-bisque-shade: #e0c9ac;
  --ion-color-bisque-tint: #ffe7ca;
  --ion-color-moss: #acdf87;
  --ion-color-moss-rgb: 172, 223, 135;
  --ion-color-moss-contrast: #000;
  --ion-color-moss-contrast-rgb: 0, 0, 0;
  --ion-color-moss-shade: #97c477;
  --ion-color-moss-tint: #b4e293;
  --ion-color-coral: #ff7f50;
  --ion-color-coral-rgb: 255, 127, 80;
  --ion-color-coral-contrast: #000;
  --ion-color-coral-contrast-rgb: 0, 0, 0;
  --ion-color-coral-shade: #e07046;
  --ion-color-coral-tint: #ff8c62;
  --ion-color-honeydew: #f0fff0;
  --ion-color-honeydew-rgb: 240, 255, 240;
  --ion-color-honeydew-contrast: #000;
  --ion-color-honeydew-contrast-rgb: 0, 0, 0;
  --ion-color-honeydew-shade: #d3e0d3;
  --ion-color-honeydew-tint: #f2fff2;
  --ion-color-fog: #e8e8e8;
  --ion-color-fog-rgb: 232, 232, 232;
  --ion-color-fog-contrast: #000;
  --ion-color-fog-contrast-rgb: 0, 0, 0;
  --ion-color-fog-shade: #ccc;
  --ion-color-fog-tint: #eaeaea;
  --ion-color-amber: #f4b028;
  --ion-color-amber-rgb: 244, 176, 40;
  --ion-color-amber-contrast: #000;
  --ion-color-amber-contrast-rgb: 0, 0, 0;
  --ion-color-amber-shade: #d79b23;
  --ion-color-amber-tint: #f5b83e;
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-red {
  --ion-color-base: var(--ion-color-red);
  --ion-color-base-rgb: var(--ion-color-red-rgb);
  --ion-color-contrast: var(--ion-color-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-red-shade);
  --ion-color-tint: var(--ion-color-red-tint);
}

.ion-color-lime {
  --ion-color-base: var(--ion-color-lime);
  --ion-color-base-rgb: var(--ion-color-lime-rgb);
  --ion-color-contrast: var(--ion-color-lime-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lime-contrast-rgb);
  --ion-color-shade: var(--ion-color-lime-shade);
  --ion-color-tint: var(--ion-color-lime-tint);
}

.ion-color-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}

.ion-color-aqua {
  --ion-color-base: var(--ion-color-aqua);
  --ion-color-base-rgb: var(--ion-color-aqua-rgb);
  --ion-color-contrast: var(--ion-color-aqua-contrast);
  --ion-color-contrast-rgb: var(--ion-color-aqua-contrast-rgb);
  --ion-color-shade: var(--ion-color-aqua-shade);
  --ion-color-tint: var(--ion-color-aqua-tint);
}

.ion-color-yellow {
  --ion-color-base: var(--ion-color-yellow);
  --ion-color-base-rgb: var(--ion-color-yellow-rgb);
  --ion-color-contrast: var(--ion-color-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow-shade);
  --ion-color-tint: var(--ion-color-yellow-tint);
}

.ion-color-fuchsia {
  --ion-color-base: var(--ion-color-fuchsia);
  --ion-color-base-rgb: var(--ion-color-fuchsia-rgb);
  --ion-color-contrast: var(--ion-color-fuchsia-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fuchsia-contrast-rgb);
  --ion-color-shade: var(--ion-color-fuchsia-shade);
  --ion-color-tint: var(--ion-color-fuchsia-tint);
}

.ion-color-silver {
  --ion-color-base: var(--ion-color-silver);
  --ion-color-base-rgb: var(--ion-color-silver-rgb);
  --ion-color-contrast: var(--ion-color-silver-contrast);
  --ion-color-contrast-rgb: var(--ion-color-silver-contrast-rgb);
  --ion-color-shade: var(--ion-color-silver-shade);
  --ion-color-tint: var(--ion-color-silver-tint);
}

.ion-color-gray {
  --ion-color-base: var(--ion-color-gray);
  --ion-color-base-rgb: var(--ion-color-gray-rgb);
  --ion-color-contrast: var(--ion-color-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray-shade);
  --ion-color-tint: var(--ion-color-gray-tint);
}

.ion-color-maroon {
  --ion-color-base: var(--ion-color-maroon);
  --ion-color-base-rgb: var(--ion-color-maroon-rgb);
  --ion-color-contrast: var(--ion-color-maroon-contrast);
  --ion-color-contrast-rgb: var(--ion-color-maroon-contrast-rgb);
  --ion-color-shade: var(--ion-color-maroon-shade);
  --ion-color-tint: var(--ion-color-maroon-tint);
}

.ion-color-olive {
  --ion-color-base: var(--ion-color-olive);
  --ion-color-base-rgb: var(--ion-color-olive-rgb);
  --ion-color-contrast: var(--ion-color-olive-contrast);
  --ion-color-contrast-rgb: var(--ion-color-olive-contrast-rgb);
  --ion-color-shade: var(--ion-color-olive-shade);
  --ion-color-tint: var(--ion-color-olive-tint);
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-purple {
  --ion-color-base: var(--ion-color-purple);
  --ion-color-base-rgb: var(--ion-color-purple-rgb);
  --ion-color-contrast: var(--ion-color-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple-shade);
  --ion-color-tint: var(--ion-color-purple-tint);
}

.ion-color-teal {
  --ion-color-base: var(--ion-color-teal);
  --ion-color-base-rgb: var(--ion-color-teal-rgb);
  --ion-color-contrast: var(--ion-color-teal-contrast);
  --ion-color-contrast-rgb: var(--ion-color-teal-contrast-rgb);
  --ion-color-shade: var(--ion-color-teal-shade);
  --ion-color-tint: var(--ion-color-teal-tint);
}

.ion-color-navy {
  --ion-color-base: var(--ion-color-navy);
  --ion-color-base-rgb: var(--ion-color-navy-rgb);
  --ion-color-contrast: var(--ion-color-navy-contrast);
  --ion-color-contrast-rgb: var(--ion-color-navy-contrast-rgb);
  --ion-color-shade: var(--ion-color-navy-shade);
  --ion-color-tint: var(--ion-color-navy-tint);
}

.ion-color-turquoise {
  --ion-color-base: var(--ion-color-turquoise);
  --ion-color-base-rgb: var(--ion-color-turquoise-rgb);
  --ion-color-contrast: var(--ion-color-turquoise-contrast);
  --ion-color-contrast-rgb: var(--ion-color-turquoise-contrast-rgb);
  --ion-color-shade: var(--ion-color-turquoise-shade);
  --ion-color-tint: var(--ion-color-turquoise-tint);
}

.ion-color-salmon {
  --ion-color-base: var(--ion-color-salmon);
  --ion-color-base-rgb: var(--ion-color-salmon-rgb);
  --ion-color-contrast: var(--ion-color-salmon-contrast);
  --ion-color-contrast-rgb: var(--ion-color-salmon-contrast-rgb);
  --ion-color-shade: var(--ion-color-salmon-shade);
  --ion-color-tint: var(--ion-color-salmon-tint);
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-beige {
  --ion-color-base: var(--ion-color-beige);
  --ion-color-base-rgb: var(--ion-color-beige-rgb);
  --ion-color-contrast: var(--ion-color-beige-contrast);
  --ion-color-contrast-rgb: var(--ion-color-beige-contrast-rgb);
  --ion-color-shade: var(--ion-color-beige-shade);
  --ion-color-tint: var(--ion-color-beige-tint);
}

.ion-color-lavender {
  --ion-color-base: var(--ion-color-lavender);
  --ion-color-base-rgb: var(--ion-color-lavender-rgb);
  --ion-color-contrast: var(--ion-color-lavender-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lavender-contrast-rgb);
  --ion-color-shade: var(--ion-color-lavender-shade);
  --ion-color-tint: var(--ion-color-lavender-tint);
}

.ion-color-dodger {
  --ion-color-base: var(--ion-color-dodger);
  --ion-color-base-rgb: var(--ion-color-dodger-rgb);
  --ion-color-contrast: var(--ion-color-dodger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dodger-contrast-rgb);
  --ion-color-shade: var(--ion-color-dodger-shade);
  --ion-color-tint: var(--ion-color-dodger-tint);
}

.ion-color-dodger {
  --ion-color-base: var(--ion-color-dodger);
  --ion-color-base-rgb: var(--ion-color-dodger-rgb);
  --ion-color-contrast: var(--ion-color-dodger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dodger-contrast-rgb);
  --ion-color-shade: var(--ion-color-dodger-shade);
  --ion-color-tint: var(--ion-color-dodger-tint);
}

.ion-color-dodger {
  --ion-color-base: var(--ion-color-dodger);
  --ion-color-base-rgb: var(--ion-color-dodger-rgb);
  --ion-color-contrast: var(--ion-color-dodger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dodger-contrast-rgb);
  --ion-color-shade: var(--ion-color-dodger-shade);
  --ion-color-tint: var(--ion-color-dodger-tint);
}

.ion-color-orchid {
  --ion-color-base: var(--ion-color-orchid);
  --ion-color-base-rgb: var(--ion-color-orchid-rgb);
  --ion-color-contrast: var(--ion-color-orchid-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orchid-contrast-rgb);
  --ion-color-shade: var(--ion-color-orchid-shade);
  --ion-color-tint: var(--ion-color-orchid-tint);
}

.ion-color-wheat {
  --ion-color-base: var(--ion-color-wheat);
  --ion-color-base-rgb: var(--ion-color-wheat-rgb);
  --ion-color-contrast: var(--ion-color-wheat-contrast);
  --ion-color-contrast-rgb: var(--ion-color-wheat-contrast-rgb);
  --ion-color-shade: var(--ion-color-wheat-shade);
  --ion-color-tint: var(--ion-color-wheat-tint);
}

.ion-color-pink {
  --ion-color-base: var(--ion-color-pink);
  --ion-color-base-rgb: var(--ion-color-pink-rgb);
  --ion-color-contrast: var(--ion-color-pink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink-shade);
  --ion-color-tint: var(--ion-color-pink-tint);
}

.ion-color-violet {
  --ion-color-base: var(--ion-color-violet);
  --ion-color-base-rgb: var(--ion-color-violet-rgb);
  --ion-color-contrast: var(--ion-color-violet-contrast);
  --ion-color-contrast-rgb: var(--ion-color-violet-contrast-rgb);
  --ion-color-shade: var(--ion-color-violet-shade);
  --ion-color-tint: var(--ion-color-violet-tint);
}

.ion-color-bisque {
  --ion-color-base: var(--ion-color-bisque);
  --ion-color-base-rgb: var(--ion-color-bisque-rgb);
  --ion-color-contrast: var(--ion-color-bisque-contrast);
  --ion-color-contrast-rgb: var(--ion-color-bisque-contrast-rgb);
  --ion-color-shade: var(--ion-color-bisque-shade);
  --ion-color-tint: var(--ion-color-bisque-tint);
}

.ion-color-moss {
  --ion-color-base: var(--ion-color-moss);
  --ion-color-base-rgb: var(--ion-color-moss-rgb);
  --ion-color-contrast: var(--ion-color-moss-contrast);
  --ion-color-contrast-rgb: var(--ion-color-moss-contrast-rgb);
  --ion-color-shade: var(--ion-color-moss-shade);
  --ion-color-tint: var(--ion-color-moss-tint);
}

.ion-color-coral {
  --ion-color-base: var(--ion-color-coral);
  --ion-color-base-rgb: var(--ion-color-coral-rgb);
  --ion-color-contrast: var(--ion-color-coral-contrast);
  --ion-color-contrast-rgb: var(--ion-color-coral-contrast-rgb);
  --ion-color-shade: var(--ion-color-coral-shade);
  --ion-color-tint: var(--ion-color-coral-tint);
}

.ion-color-honeydew {
  --ion-color-base: var(--ion-color-honeydew);
  --ion-color-base-rgb: var(--ion-color-honeydew-rgb);
  --ion-color-contrast: var(--ion-color-honeydew-contrast);
  --ion-color-contrast-rgb: var(--ion-color-honeydew-contrast-rgb);
  --ion-color-shade: var(--ion-color-honeydew-shade);
  --ion-color-tint: var(--ion-color-honeydew-tint);
}

.ion-color-fog {
  --ion-color-base: var(--ion-color-fog);
  --ion-color-base-rgb: var(--ion-color-fog-rgb);
  --ion-color-contrast: var(--ion-color-fog-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fog-contrast-rgb);
  --ion-color-shade: var(--ion-color-fog-shade);
  --ion-color-tint: var(--ion-color-fog-tint);
}

.ion-color-amber {
  --ion-color-base: var(--ion-color-amber);
  --ion-color-base-rgb: var(--ion-color-amber-rgb);
  --ion-color-contrast: var(--ion-color-amber-contrast);
  --ion-color-contrast-rgb: var(--ion-color-amber-contrast-rgb);
  --ion-color-shade: var(--ion-color-amber-shade);
  --ion-color-tint: var(--ion-color-amber-tint);
}

/**
  * Barcode Scanning
  * -------------------------------------------
  */
body.barcode-scanning-active {
  visibility: hidden;
  --background: transparent;
  --ion-background-color: transparent;
}

.barcode-scanning-modal {
  visibility: visible;
  z-index: 1000;
}

@media (prefers-color-scheme: dark) {
  .barcode-scanning-modal {
    --background: transparent;
    --ion-background-color: transparent;
  }
}
